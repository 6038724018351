import React from "react";
import withI18n from "i18n/withI18n";
import { PageProps } from "gatsby";

import Teaser from "components/Teaser";
import SEO from "components/seo";
import { Viewport } from "components/Meta";

import { Interests, WhitepaperRequstTags } from "constants/mailchimp";

import keelungIcon from "assets/ic_keelung.svg";
import HaskellIcon from "assets/ic_haskell.inline.svg";
import FlexIcon from "assets/ic_flexible.inline.svg";
import ListIcon from "assets/ic_list.inline.svg";

const description =
  "We simplify building Zero-Knowledge Proofs, making it easy to adapt ZK applications whenever the market demands.";

const KeelungTeaser = ({ location }: PageProps) => (
  <>
    <Viewport />
    <SEO
      title="Keelung"
      description={description}
      pathname={location.pathname}
      titlePrefix
      // locale // ToDo: enable this when i18n is ready
    />
    <Teaser
      title={{ name: "Keelung", iconUrl: keelungIcon }}
      desc={description}
      features={[
        {
          icon: HaskellIcon,
          outline: "Write in Haskell",
          desc: (
            <>
              Keelung is a domain-specific language (DSL) embedded in Haskell. This allows
              developers to write high-level ZKPs guarded by Haskell’s advanced type system while
              leveraging the ecosystem and toolings of Haskell.
            </>
          ),
        },
        {
          icon: FlexIcon,
          outline: "Flexibility built-in",
          desc: (
            <>
              Build for today, adapt for tomorrow. Don’t be limited by the design choices you make
              today. Keelung allows you to easily adapt your zero-knowledge components in the
              future.
            </>
          ),
        },
        {
          icon: ListIcon,
          outline: "Cross-chain compatible",
          desc: (
            <>
              Keelung gives you the flexibility to adjust the downstream proving system targeted by
              the R1CS circuit. This means you only need to compile one R1CS circuit to take
              advantage of an entire ecosystem of proving systems.
            </>
          ),
        },
      ]}
      interests={[Interests.Keelung]}
      whitepaperTag={WhitepaperRequstTags.Keelung}
    />
  </>
);

export default withI18n(KeelungTeaser);
